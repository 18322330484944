import { combineReducers } from "redux"
import global from "./global"
import home from "./home"
import cart from "./cart"
import products from "./products"
import barelyTypography from "./barelyTypography"
import sixFeet from "./sixFeet"

export default combineReducers({
  global,
  home,
  products,
  cart,
  barelyTypography,
  sixFeet,
})
