// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barely-typography-js": () => import("./../src/pages/barely-typography.js" /* webpackChunkName: "component---src-pages-barely-typography-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-radio-hour-js": () => import("./../src/pages/radio-hour.js" /* webpackChunkName: "component---src-pages-radio-hour-js" */),
  "component---src-pages-reviews-js": () => import("./../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shop-index-js": () => import("./../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-six-feet-js": () => import("./../src/pages/shop/six-feet.js" /* webpackChunkName: "component---src-pages-shop-six-feet-js" */)
}

