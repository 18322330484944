const LOADING = "loading"
const HIDE_BODY_COPY = "hideBodyCopy"
const BACKGROUND_HOVER_IMAGE = "backgroundHoverImage"
const CURSOR_HOVER_IMAGE = "cursorHoverImage"
const MOUSE_POSITION = "mousePosition"

const initialState = {
  loading: true,
  hideBodyCopy: false,
  backgroundHoverImage: false,
  cursorHoverImage: null,
  hoverImageCount: 12,
  mousePosition: { x: 0, y: 0 },
}

export const setMousePosition = obj => {
  return {
    type: MOUSE_POSITION,
    obj,
  }
}

export const setLoading = bool => {
  return {
    type: LOADING,
    bool,
  }
}

export const setHideBodyCopy = bool => {
  return {
    type: HIDE_BODY_COPY,
    bool,
  }
}

export const setBackgroundHoverImage = bool => {
  return {
    type: BACKGROUND_HOVER_IMAGE,
    bool,
  }
}

export const setCursorHoverImage = string => {
  return {
    type: CURSOR_HOVER_IMAGE,
    string,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MOUSE_POSITION:
      return Object.assign({}, state, {
        mousePosition: action.obj,
      })
    case BACKGROUND_HOVER_IMAGE:
      return Object.assign({}, state, {
        backgroundHoverImage: action.bool,
      })
    case CURSOR_HOVER_IMAGE:
      return Object.assign({}, state, {
        cursorHoverImage: action.string,
      })
    case HIDE_BODY_COPY:
      return Object.assign({}, state, {
        hideBodyCopy: action.bool,
      })
    case LOADING:
      return Object.assign({}, state, {
        loading: action.bool,
      })
    default:
      return state
  }
}
