const VARIANT_MODAL = "variantModal"
const ORDER_MODAL_OPEN = "orderModalOpen"
const ORDER_DETAILS = "orderDetails"

const initialState = {
  variantModal: {
    isOpen: false,
    productId: null,
    productSize: null,
    productQuantity: null,
    sizesUnavailable: [],
  },
  orderModalOpen: false,
  orderDetails: [],
}

export const updateVariantModal = obj => {
  return {
    type: VARIANT_MODAL,
    obj,
  }
}

export const setOrderModalOpen = bool => {
  return {
    type: ORDER_MODAL_OPEN,
    bool,
  }
}

export const updateOrderDetails = obj => {
  return {
    type: ORDER_DETAILS,
    obj,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VARIANT_MODAL:
      return Object.assign({}, state, {
        variantModal: action.obj,
      })
    case ORDER_MODAL_OPEN:
      return Object.assign({}, state, {
        orderModalOpen: action.bool,
      })
    case ORDER_DETAILS:
      if (action.obj.action === "add") {
        return {
          ...state,
          orderDetails: [...state.orderDetails, action.obj],
        }
      } else if (action.obj.action === "remove") {
        return {
          ...state,
          orderDetails: state.orderDetails.slice(0, action.obj.index),
        }
      }

    default:
      return state
  }
}
