const LOADING = "loading";

const initialState = {
  loading: true
};

export const setLoading = bool => {
  return {
    type: LOADING,
    bool
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, state, {
        loading: action.bool
      });
    default:
      return state;
  }
};
