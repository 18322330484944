const PRODUCTS = "products"
const PRODUCT_META_DATA = "productMetaData"

const initialState = {
  products: [],
  productMetaData: {
    "the-og-hoodie": {
      title: "",
      description: "",
      price: "",
      primaryImage: "",
      secondaryImage: "",
      cartImage: "",
      variants: [
        {
          sku: "000000-S",
          size: "S",
          variantId: 33450215440517,
        },
        {
          sku: "000000-M",
          size: "M",
          variantId: 33450215473285,
        },
        {
          sku: "000000-L",
          size: "L",
          variantId: 33450215506053,
        },
        {
          sku: "000000-XL",
          size: "XL",
          variantId: 33450215571589,
        },
      ],
    },

    "world-takeover-hoodie": {
      title: "",
      description: "",
      price: "",
      primaryImage: "",
      secondaryImage: "",
      cartImage: "",
      variants: [
        {
          sku: "000001-S",
          size: "S",
          variantId: 33462960357509,
        },
        {
          sku: "000001-M",
          size: "M",
          variantId: 33462960390277,
        },
        {
          sku: "000001-L",
          size: "L",
          variantId: 33462960423045,
        },
        {
          sku: "000001-XL",
          size: "XL",
          variantId: 33462960455813,
        },
      ],
    },
    "lockup-hoodie": {
      title: "",
      description: "",
      price: "",
      primaryImage: "",
      secondaryImage: "",
      cartImage: "",
      variants: [
        {
          sku: "000002-S",
          size: "S",
          variantId: 33462969630853,
        },
        {
          sku: "000002-M",
          size: "M",
          variantId: 33462969663621,
        },
        {
          sku: "000002-L",
          size: "L",
          variantId: 33462969729157,
        },
        {
          sku: "000002-XL",
          size: "XL",
          variantId: 33462969761925,
        },
      ],
    },
    "spot-check-crewneck": {
      title: "",
      description: "",
      price: "",
      primaryImage: "",
      secondaryImage: "",
      cartImage: "",
      variants: [
        {
          sku: "000003-S",
          size: "S",
          variantId: 33462978773125,
        },
        {
          sku: "000003-M",
          size: "M",
          variantId: 33462978805893,
        },
        {
          sku: "000003-L",
          size: "L",
          variantId: 33462978838661,
        },
        {
          sku: "000003-XL",
          size: "XL",
          variantId: 33462978904197,
        },
      ],
    },
    "six-feet-shirt-blue": {
      title: "",
      description: "",
      price: "",
      primaryImage: "",
      secondaryImage: "",
      cartImage: "",
      variants: [
        {
          sku: "000004-S",
          size: "S",
          variantId: 34547693125786,
        },
        {
          sku: "000004-M",
          size: "M",
          variantId: 34547693158554,
        },
        {
          sku: "000004-L",
          size: "L",
          variantId: 34547693191322,
        },
        {
          sku: "000004-XL",
          size: "XL",
          variantId: 34547693256858,
        },
      ],
    },
    "six-feet-shirt-red": {
      title: "",
      description: "",
      price: "",
      primaryImage: "",
      secondaryImage: "",
      cartImage: "",
      variants: [
        {
          sku: "000005-S",
          size: "S",
          variantId: 34547742408858,
        },
        {
          sku: "000005-M",
          size: "M",
          variantId: 34547742441626,
        },
        {
          sku: "000005-L",
          size: "L",
          variantId: 34547742474394,
        },
        {
          sku: "000005-XL",
          size: "XL",
          variantId: 34547742507162,
        },
      ],
    },
  },
}

export const setProducts = obj => {
  return {
    type: PRODUCTS,
    obj,
  }
}

export const setProductMetaData = obj => {
  return {
    type: PRODUCT_META_DATA,
    obj,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS:
      return Object.assign({}, state, {
        products: action.obj,
      })
    case PRODUCT_META_DATA:
      return Object.assign({}, state, {
        productMetaData: action.obj,
      })
    default:
      return state
  }
}
